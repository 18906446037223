// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-orodje-js": () => import("./../src/templates/orodje.js" /* webpackChunkName: "component---src-templates-orodje-js" */),
  "component---src-templates-analiza-js": () => import("./../src/templates/analiza.js" /* webpackChunkName: "component---src-templates-analiza-js" */),
  "component---src-templates-ulica-js": () => import("./../src/templates/ulica.js" /* webpackChunkName: "component---src-templates-ulica-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analize-js": () => import("./../src/pages/analize.js" /* webpackChunkName: "component---src-pages-analize-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-na-ulice-js": () => import("./../src/pages/na_ulice.js" /* webpackChunkName: "component---src-pages-na-ulice-js" */),
  "component---src-pages-orodjarna-js": () => import("./../src/pages/orodjarna.js" /* webpackChunkName: "component---src-pages-orodjarna-js" */),
  "component---src-pages-orodjarna-pisma-js": () => import("./../src/pages/orodjarna/pisma.js" /* webpackChunkName: "component---src-pages-orodjarna-pisma-js" */),
  "component---src-pages-predlogi-js": () => import("./../src/pages/predlogi.js" /* webpackChunkName: "component---src-pages-predlogi-js" */)
}

